import { getGlobalStyle } from '@smu-chile/pkg-unimarc-components/helpers'

// Put shared constants here
export const status = 'UP'
export const version = '0.0.1'
export const serverError = 'Internal Server Error'
export const startServer = 'Express server started on port'
export const requestError = 'Request Error'
export const genericReponseOK = 'OK'
export const BAD_REQUEST_MESSAGE = 'Bad Request'
export const env = process.env.NODE_ENV
export const DAYS = [
  'Domingo',
  'Lunes',
  'Martes',
  'Miércoles',
  'Jueves',
  'Viernes',
  'Sábado'
]

// HTTP verbose
export const GET = 'GET'
export const POST = 'POST'
export const PATCH = 'PATCH'
export const DELETE = 'DELETE'
export const PUT = 'PUT'

// order statuses
export const ORDER_TYPE_BY_ID = {
  canceled: '4H4cPCIXJvrifJqY2J5P2Q', //-> cancelada
  default: '1qw22fpTNRxuKXBgB2sqVC',
  delivered: '1qw22fpTNRxuKXBgB2sqVC', //-> entregada retirada
  incomplete: '7peG6Tvl4lT3hBd1hDttNq', //-> rechazada
  invoiced: '1iYigZxrenkZZWTC7mU8KU', // - En preparación
  invoice: '1iYigZxrenkZZWTC7mU8KU', // - En preparación
  'ready-for-handling': '1iYigZxrenkZZWTC7mU8KU', // -> Confirmada
  'window-to-cancel': '1iYigZxrenkZZWTC7mU8KU', //-> solicitud recibida
  sent: '1iYigZxrenkZZWTC7mU8KU' // -> En camino  - lista para retiro
}

export const CLAIM_STEPS = [
  'step0',
  'step1',
  'step2',
  'step3',
  'step4',
  'step5',
  'step6',
  'step7',
  'step8',
  'step9',
  'step10'
]

export const CLAIM_STEPS_NAME_PRODUCTS = [
  'Producto incorrecto',
  'Producto faltante',
  'Compra dañada o problema de calidad'
]

export const CLAIM_STEP_DELIVERED_PURSHASE = [
  'Problema con productos',
  'Incumplimiento de entrega',
  'Cupones y promociones',
  'Cobro incorrecto o duplicado',
  'Devoluciones',
  'Otro'
]

export const CLAIM_STEP_CONFIRMATED_PURSHASE = [
  'Incumplimiento de entrega',
  'Cupones y promociones',
  'Cobro incorrecto o duplicado',
  'Otro'
]

export const CLAIM_STEP_CANCELED_PURSHASE = [
  'Mi compra se canceló',
  'Aún no se hace deolución de mi dinero',
  'Otro'
]

export const CLAIM_STEP_REJECTED_PURSHASE = [
  'Mi compra se rechazó',
  'Aún no se hace deolución de mi dinero',
  'Otro'
]

export const ID_CONTENT_FUL_CLAIM_FLOW_BY_TITTLE = {
  0: '1qw22fpTNRxuKXBgB2sqVC', // 'Problema con productos'
  1: '1iYigZxrenkZZWTC7mU8KU', //Incumplimiento de entrega
  2: '4H4cPCIXJvrifJqY2J5P2Q', //mi compra se canceló sin previo aviso
  3: '7peG6Tvl4lT3hBd1hDttNq' //mi compra se rechazó sin previo aviso
}

export const CLAIM_STEPS_TITLE_BY_OPTION = {
  default: '¿Cuál es el problema?',
  [CLAIM_STEP_DELIVERED_PURSHASE[1]]: '¿Por qué no cumplimos con tu entrega?',
  [CLAIM_STEP_DELIVERED_PURSHASE[2]]:
    '¿Qué problema tuviste con los cupones y promociones?',
  [CLAIM_STEP_DELIVERED_PURSHASE[3]]:
    '¿Qué problema tuviste con el cobro de la compra?',
  [CLAIM_STEP_DELIVERED_PURSHASE[4]]: '¿Qué problema tuviste con la devolución?'
}

export const DEFAULT_CART_LIMIT = 100

export const ORDER_PER_PAGE_MY_LISTS = 4

export const MENU_ORDER_DETAIL_BUTTONS = {
  0: 'whotG5XEBlseluCBTYTrH',
  1: '6HC3XC2oKrW6VL4zj5pvXF',
  2: '6vfAZBZYsMDiEn4xbPTytA',
  3: '3we8Qre92yRCcW9EMWPo6W'
}

export const ID_MENU_ORDER_DETAIL = {
  0: '7ClHyhI9Xp4VpkuMEHPFLd'
}

export const ID_SIDE_MENU_BUTTONS = {
  0: '5bZqSmixzYCeYWj3SbDCJG'
}

export const MENU_SIDE_MENU_BUTTONS = {
  0: '78a8EuEwgRs6vpULkCkMkU', //mis compras
  1: '5QG9mFhsiP1qGJkAB9SGlA', //mis listas
  2: '74t4fmBRXPozVLqBG4pSTZ', //mis medios de pago
  3: '3H3yIsReYvFB1mcB7heqxT' //cerrar sesion
}

// contentful id's to get assets
export const MODAL_REPEAT_PURCHASE_IMG = {
  0: '7vkcc9GVRncJUxTGbTT8xh'
}

export const ICON_SECTION_WEBVIEW_HELPCENTER = {
  logo: '6qgkvtcJJdRtuUnmEz1P1n',
  phone: '4E8nqrVmg4aP9uY19bnN8f',
  card: '1FR5MIodOwPlSZ5zqyJw8s'
}

export const IMAGES_ICON_ORDERS_CANCEL = {
  0: '3D9b9JcY2yJHKitX5245W8'
}

export const IMAGES_ICON_ORDERS_STORE = {
  0: '4Hsg5W7ayBDrMB1pLNzjwP'
}

export const IMAGES_ICON_ORDERS_MOTO = {
  0: '684KQo1Il6Mgj0Cj4SwFRd'
}

export const IMAGE_BAGUETE = {
  0: '6IDrkYWr2O5Y60iBRLrKqJ'
}

export const IMAGE_SLICED_BREAD = {
  0: '3UNc6YIHt3zYgBGd3Xq4Gc'
}

export const IMAGE_POMEGRANATE = 'mPKzjsNCK9F61uZweUBop'

export const IMAGE_COCO = 'PCJVjCTrW7ZIUxKqNpHji'

export const IMAGE_CHERRY = '5nuKbu8mojbJyJKDAx29tS'

export const IMAGE_BROCOLI = 'XDRs5O7ULAbnoUfs2Fmy2'

export const IMAGES_ICON_REQUEST_NEW = {
  0: '105jIztDhFV7fX9HwCsBmR'
}

export const IMAGES_ICON_REQUEST_OPEN = {
  0: '5RmCMv0CZvCGbkAriGKgBG'
}

export const IMAGES_ICON_REQUEST_CLOSED = {
  0: '4YiB692Dt3Ryzko6PT5BP8'
}

export const MODAL_DELETE_CARD_IMG = {
  0: '2RyenQWXzvyWz0naipeJDG'
}

export const ID_HEADER_MENU_BUTTONS = {
  0: '56SGwq2UnvyvygqTnEU4kn'
}

export const ID_HELP_CENTER_OTHERS_CF = {
  0: '7b4sA3W04ZFt5EhbBbA3xL'
}
export const IMAGE_EMPTY_ORDERS = '2mjEtu9jOEZUtS6ycmahKd'
export const IMAGE_EMPTY_ORDERS_NOT_FOUND = '3tG8MXOEAHRAa7A7lVFYtI'
export const IMAGE_EMPTY_ORDERS_REJECTED = '3lhtw0ywEjh1zlzSHxRYRi'

export const IMAGE_DONUTS = '4thkbKzGmE0xIeXNAck51a'

export const MENU_HEADER_MENU_BUTTONS = {
  0: '7FKPzLEKEXEaQAlWR1Nx9F', //mis compras
  1: 'sk4g47CIf22WR9Jmv4zeK', //mis listas
  2: '6j2YDNLPRQTH5jmNVwmOKL', //mis medios de pago
  3: '7jXhAAv8B07UA3JovAkHhi', //cerrar sesion
  4: '6Ys6uV0w4nlHHyHks8h1HB', //cupones
  5: '4sRvsLv14mFzhnkJUdWrcT', //mis datos personales
  6: '3nY7pId6PtAYaUAMf0tXop', //necesito ayuda
  7: '1zhNIHmu50U4iOBchs7Xqt', //membersía
  8: '1ZhDS6o1s9jtFzIkIRbKtr' //solicitudes
}

export const CLAIMFLOW_SUCCESS_LETTUCE_IMG = {
  0: '6kGdO72J63yYjBBGL6ARLY'
}

export const TOOLTIP_EDIT_PERSONAL_INFORMATION = {
  0: '3XMeHd6bDnNwqoxJ4m5xri'
}

export const EMPTY_ORANGE_IMG = {
  0: 'biRXfIqeSvhltdz6EiwEs'
}

export const EMPTY_COOKIES_IMG = {
  0: 'xnij5kr9QxWjDRgg79U3o'
}

export const UNIMARC_ISOTYPE = {
  0: '6qgkvtcJJdRtuUnmEz1P1n'
}

export const MODAL_ADD_TO_CART_FRUITS = {
  0: '6ghHIx4LW8QXL3p1IPitMW'
}

export const MODAL_CONFIRMATION_LEMON = {
  0: '52uJk3frX8iyS9vw3uYY3G'
}

export const MODAL_LOGOUT_STRAWBERRY = {
  0: 'bquT0FyHwOW8l0ntqPZpf'
}

export const MODAL_CONFIRM_CANCEL_PURCHASE = {
  0: '2pJPQecHeFSZvgS5XSt013'
}

export const MODAL_OUTPUT_EDITION_PERSONAL_DATA = {
  0: '5VsFCPUvNbxRl4u7qnIdfX',
  1: '5mGaKibGwxFGmkMAVNxoJo'
}

export const TEXT_EDIT_PERSONAL_DATA = {
  0: '3XMeHd6bDnNwqoxJ4m5xri'
}

export const MODAL_ERROR_CANCEL_PURCHASE = {
  0: 'Ic3qPGdhBDsTGARPc1OMD'
}

export const MODAL_DELETE_LIST_APPLE = {
  0: '2RyenQWXzvyWz0naipeJDG'
}

export const EMPTY_LIST_KIWI = {
  0: '1ymhhQAJBTH20Y2qIlhVYJ'
}

export const ORDER_PER_PAGE_DESKTOP = 4
export const ORDER_PER_PAGE_MOBILE = 6

export const ORDER_PER_PAGE_DESKTOP_ZENDESK = 5
export const ORDER_PER_PAGE_MOBILE_ZENDESK = 5

export const ID_STORE_CONTENT_FUL_NOT_FOUND = 'unimarc.cl'
export const ID_CONSTANT_IF_NOT_FOUND_STORE_CONTENT_FUL =
  '0PuS7ls2Cz5z6Yz0z5hNe'
export const IDS_CONTENT_FUL_BY_STORE = {
  0: '2AtC9cwRTHSiEH61EDnGjl'
}
export const IDS_CONTENTFUL_HELPCENTER = {
  0: '5Jayppf0xpCUZjD18wgbBz'
}

export const IDS_CONTENTFUL_MEMBERSHIP = {
  0: '3mCaU7Q1ftOUwJZH0aWTUn'
}
export const IDS_CONTENTFUL_MEMBERSHIP_FLOW_CANCELED = {
  0: '70oF393g6BVyxr0woURYbr'
}

export const IDS_CONTENTFUL_MEMBERSHIP_FLOW_CANCELED_ORANGE = {
  0: '4gJXbXrJv5zWek5Bw5UZSC'
}

export const IDS_CONTENTFUL_MEMBERSHIP_FLOW_CARD_DELETE = {
  0: '1PaRkGO7pQv41gqQvi5WeB'
}

export const IDS_CONTENTFUL_MEMBERSHIP_FLOW_CANCEL = {
  0: '4jTBGHBxfDN7yk6E9gLSdC'
}

// BANCO ESTADO ICON
export const BANK_STATE_ICON = {
  0: '3OLdkh2oq69m1X1xoXJF4B'
}

export const VALIDATE_SC_MODAL_IMAGE = {
  0: '39mfaeyBygzQvDgVq4h7bR'
}

export const SOMETHING_IS_WRONG_MODAL_IMAGE = {
  0: '2TUvQviWSgMU01kL1uMMqW'
}
export const TEXT_UNIMARC_HELPCENTER = 'Unimarc.cl'
export const TEXT_UNIPAY_HELPCENTER = 'Unipay'

export const TEXT_MEMBERSHIP_SAVING_SHIPPING = 'Ahorro en despachos'

export const MEMBERSHIP_SAVINGS = {
  0: '7qnVTZTlBR73ufsQRqhyJ5'
}

export const HELP_CENTER = '7vHXIgtAQDD3JFVfMHunMR'

export const LOGIN_MODAL_IMAGE = '4iejdhvwSq9nkDDaDvkdH7'
